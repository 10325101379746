<template>
  <div
    v-if="value"
    class="price-info"
    :class="{
      'price-info--reduction': value.old
    }"
    :aria-label="$t('checkout.basket.position.header.price') + ' ' + value.current"
  >
    <div
      class="price-info__old"
      v-if="value.old"
    >
      <span class="price-info__old-text">{{ $t('price.instead_of') }}</span>
      <span class="price-info__old-value">
        <span class="price-info__old-value-currency">
          {{ value.currency }}
        </span>
        <span class="price-info__old-value-price">
          {{ oldValue }}
        </span>
      </span>
    </div>

    <div class="price-info__current">
      <span class="price-info__currency">
        {{ value.currency }}
      </span>
      <span class="price-info__value">
        <span class="price-info__value-euro">{{ splitValue[0] }}</span>
        <span class="price-info__value-cent">{{ splitValue[1] }}</span>
      </span>
      <span
        v-if="showStar"
        class="price-info__asterisk"
      >
        *
      </span>
      <span
        class="price-info__savedPercentage price-info--savings-max"
        v-if="value.savedPercentage && withSavingsMax && value.savedPercentage > 50"
        v-html="$t('product.price.savedPercentage.savingsMax')"
      />
      <span
        class="price-info__savedPercentage"
        v-else-if="value.savedPercentage"
      >
        -{{ value.savedPercentage }}%
      </span>
    </div>

    <div class="price-info__unit-wrapper">
      <div class="price-info__unit">
        <span class="price-info__unit-text">
          {{ getUnitText() }}
        </span>
        <span
          v-if="value.unitPrice"
          class="price-info__unit-price"
        >
          {{ value.unitPrice }}<template v-if="value.unit">/{{ value.unit }}</template>
        </span>
      </div>
      <div
        v-if="showTax"
        class="price-info__tax"
        v-html="$t(showShippingIncluded ? messageKey.deliveryInclShip : messageKey.deliveryInclVat, [shippingInfoUrl])"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import {defineComponent, PropType} from 'vue';
  import type Price from '../../../bps/types/Price';

  export default defineComponent({
    name: 'PriceInfo',

    props: {
      value: {
        type: Object as PropType<Price>,
        required: true
      },
      showTax: {
        type: Boolean,
        default: true
      },
      showShippingIncluded: {
        type: Boolean,
        default: false
      },
      shippingInfoUrl: {
        type: String,
        default: ''
      },
      withSavingsMax: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        showStar: false,
        messageKey: {
          deliveryInclShip: 'price.incl_vat_incl_ship',
          deliveryInclVat: 'price.incl_vat',
        }
      }
    },

    mounted() {
      if (window.__productConfig && window.__productConfig.messageKey) {
        this.messageKey = window.__productConfig.messageKey;
      }
    },

    computed: {
      oldValue() {
        return this.value.old && this.value.old.replace(/[ €]/g, '') || null;
      },

      splitValue() {
        if (!this.value || !this.value.currentValue) {
          return ['', ''];
        }

        return this.value.currentValue.split(/[:,]/);
      }
    },

    methods: {
      getUnitText() {
        let result = this.$t(`product.price.volumeUnit.${this.value.volumeUnit}`);
        if (this.value.unitPrice) {
          if (this.value.packageSize) {
            result += ' (' + this.value.packageSize + ')';
          }
          result += ',';
        }

        return result;
      }
    }
  });
</script>
