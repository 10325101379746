import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  key: 0,
  class: "price-info__old"
}
const _hoisted_3 = { class: "price-info__old-text" }
const _hoisted_4 = { class: "price-info__old-value" }
const _hoisted_5 = { class: "price-info__old-value-currency" }
const _hoisted_6 = { class: "price-info__old-value-price" }
const _hoisted_7 = { class: "price-info__current" }
const _hoisted_8 = { class: "price-info__currency" }
const _hoisted_9 = { class: "price-info__value" }
const _hoisted_10 = { class: "price-info__value-euro" }
const _hoisted_11 = { class: "price-info__value-cent" }
const _hoisted_12 = {
  key: 0,
  class: "price-info__asterisk"
}
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 2,
  class: "price-info__savedPercentage"
}
const _hoisted_15 = { class: "price-info__unit-wrapper" }
const _hoisted_16 = { class: "price-info__unit" }
const _hoisted_17 = { class: "price-info__unit-text" }
const _hoisted_18 = {
  key: 0,
  class: "price-info__unit-price"
}
const _hoisted_19 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["price-info", {
      'price-info--reduction': _ctx.value.old
    }]),
        "aria-label": _ctx.$t('checkout.basket.position.header.price') + ' ' + _ctx.value.current
      }, [
        (_ctx.value.old)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('price.instead_of')), 1 /* TEXT */),
              _cache[1] || (_cache[1] = _createTextVNode()),
              _createElementVNode("span", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.value.currency), 1 /* TEXT */),
                _cache[0] || (_cache[0] = _createTextVNode()),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.oldValue), 1 /* TEXT */)
              ])
            ]))
          : _createCommentVNode("v-if", true),
        _cache[8] || (_cache[8] = _createTextVNode()),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.value.currency), 1 /* TEXT */),
          _cache[3] || (_cache[3] = _createTextVNode()),
          _createElementVNode("span", _hoisted_9, [
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.splitValue[0]), 1 /* TEXT */),
            _cache[2] || (_cache[2] = _createTextVNode()),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.splitValue[1]), 1 /* TEXT */)
          ]),
          _cache[4] || (_cache[4] = _createTextVNode()),
          (_ctx.showStar)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, "\n        *\n      "))
            : _createCommentVNode("v-if", true),
          _cache[5] || (_cache[5] = _createTextVNode()),
          (_ctx.value.savedPercentage && _ctx.withSavingsMax && _ctx.value.savedPercentage > 50)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "price-info__savedPercentage price-info--savings-max",
                innerHTML: _ctx.$t('product.price.savedPercentage.savingsMax')
              }, null, 8 /* PROPS */, _hoisted_13))
            : (_ctx.value.savedPercentage)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, "\n        -" + _toDisplayString(_ctx.value.savedPercentage) + "%\n      ", 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
        ]),
        _cache[9] || (_cache[9] = _createTextVNode()),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.getUnitText()), 1 /* TEXT */),
            _cache[6] || (_cache[6] = _createTextVNode()),
            (_ctx.value.unitPrice)
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                  _createTextVNode(_toDisplayString(_ctx.value.unitPrice), 1 /* TEXT */),
                  (_ctx.value.unit)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode("/" + _toDisplayString(_ctx.value.unit), 1 /* TEXT */)
                      ], 64 /* STABLE_FRAGMENT */))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _cache[7] || (_cache[7] = _createTextVNode()),
          (_ctx.showTax)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "price-info__tax",
                innerHTML: _ctx.$t(_ctx.showShippingIncluded ? _ctx.messageKey.deliveryInclShip : _ctx.messageKey.deliveryInclVat, [_ctx.shippingInfoUrl])
              }, null, 8 /* PROPS */, _hoisted_19))
            : _createCommentVNode("v-if", true)
        ])
      ], 10 /* CLASS, PROPS */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}